<template>
  <cp-general-modal
    ref="issuerDoc"
    :title="getTitleName"
    :title-icon="`ion ion-${titleData.icon}`"
    :form-group-value="modalData"
    @onOk="handleSubmit"
  >
    <cp-input
      v-model="model.name"
      name="name"
      :label="$t('issuerDocumentModal.input.name')"
    />
    <cp-input
      v-model="model.title"
      name="title"
      :label="$t('issuerDocumentModal.input.title')"
    />
    <cp-input
      v-model="model.url"
      name="url"
      :label="$t('issuerDocumentModal.input.url')"
    />
    <cp-select
      v-model="model.type"
      name="type"
      :label="$t('issuerDocumentModal.input.type')"
      :options="types"
    />
    <cp-select
      v-model="model.category"
      name="category"
      :label="$t('issuerDocumentModal.input.category')"
      :options="categories"
    />
    <div
      v-if="titleData.type === 'Edit'"
      class="row mt-2"
    >
      <div class="col text-muted">
        <label class="form-label">
          {{ $t('issuerDocumentModal.input.created') }}
        </label> <br>
        <span>
          {{ model.createdAt | dateFilter }}
        </span>
      </div>
      <div class="col text-muted">
        <label class="form-label">
          {{ $t('issuerDocumentModal.input.updated') }}
        </label>  <br>
        <span>
          {{ model.updatedAt | dateFilter }}
        </span>
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';

export default {
  name: 'IssuerDocumentModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
  },

  props: {
    titleData: {
      type: Object,
      default: () => {},
    },
    modalData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      model: {},
      types: ['pdf', 'ppt', 'doc'],
      categories: [{
        value: 'basic',
        text: this.$t('issuerDocumentModal.option.category.basic'),
      },
      {
        value: 'investor-only',
        text: this.$t('issuerDocumentModal.option.category.investorOnly'),
      }],
    };
  },

  computed: {
    getTitleName() {
      return this.$t('issuerDocumentModal.title', [this.titleData.type]);
    },
  },

  watch: {
    modalData: {
      handler(val) {
        this.model = Object.assign({}, val);
      },
    },
  },

  methods: {
    handleSubmit() {
      const action = this.titleData.type === 'Edit' ? 'update' : 'create';
      this.$emit('onSubmit', { action, data: this.model });
    },
  },
};
</script>
