const tdClass = 'align-middle';

export default [
  { key: 'name', i18nKey: 'issuerPlatformDocuments.label.table.name', tdClass },
  { key: 'title', i18nKey: 'issuerPlatformDocuments.label.table.title', tdClass },
  { key: 'type', i18nKey: 'issuerPlatformDocuments.label.table.type', tdClass },
  { key: 'url', i18nKey: 'issuerPlatformDocuments.label.table.url', tdClass },
  { key: 'category', i18nKey: 'issuerPlatformDocuments.label.table.category', tdClass },
  { key: 'actions', label: ' ', tdClass: `text-nowrap ${tdClass} text-center` },
];
