<template>
  <div>
    <cp-head-table
      :title="$t('issuerPlatformDocuments.title')"
      :button-title="$t('issuerPlatformDocuments.button.document.add')"
      title-icon="ios-build"
      button-icon="ios-add-circle-outline"
      @onCreateElem="toCreateModal"
    />
    <cp-table
      ref="cpTable"
      get-data-action="issuerPlatformDocuments/getDocuments"
      :url-params="urlParams"
      :fields="fields"
    >
      <template
        slot="category"
        slot-scope="{ rowData }"
      >
        <b-badge
          variant="outline-info"
        >
          {{ getCategory(rowData.item.category) }}
        </b-badge>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          class="mr-3"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.edit')"
          @click="toEditModal(rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.edit') }}
        </b-btn>
        <b-btn
          v-b-tooltip.hover="true"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.delete')"
          @click="openDeleteModal(rowData.item)"
        >
          <i
            class="ion ion-ios-trash"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.delete') }}
        </b-btn>
      </template>
    </cp-table>

    <cp-delete-modal
      ref="cpDeleteAlert"
      :item-info="deleteItem"
      @delete-item="removeItem"
    />

    <cp-document-modal
      ref="issuerDocumentModal"
      :title-data="titleData"
      :modal-data="modalData"
      @onSubmit="saveChanges"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import CpTable from '~/components/shared/cp-table';
import CpHeadTable from '~/components/common/head';
import CpDeleteModal from '~/components/common/table/deleteModal';
import CpDocumentModal from './issuer-document-modal';

import fields from './fields';
import { categoryLabels } from './options';

export default {
  name: 'IssuerPlatformDocuments',

  metaInfo: {
    title: 'Issuer Platform documents',
  },

  components: {
    CpTable,
    CpHeadTable,
    CpDeleteModal,
    CpDocumentModal,
  },

  data() {
    return {
      titleData: {},
      modalData: {},
      deleteItem: null,
      fields,
      urlParams: { issuerId: this.$route.params.idIssuer },
    };
  },

  methods: {
    ...mapActions('issuerPlatformDocuments', [
      'create',
      'getById',
      'update',
      'delete',
    ]),

    getCategory(category) {
      return this.$t(categoryLabels[category]);
    },

    hideModal() {
      this.$refs.issuerDocumentModal.$refs.issuerDoc.hide();
    },

    showModal() {
      this.$refs.issuerDocumentModal.$refs.issuerDoc.show();
    },

    openDeleteModal(item) {
      this.deleteItem = item;
      this.$refs.cpDeleteAlert.$refs.deleteAlert.open();
    },

    toCreateModal() {
      this.titleData = {
        type: this.$t('common.button.add'),
        icon: 'ios-add-circle-outline',
      };

      this.modalData = { type: 'pdf', category: 'basic' };
      this.showModal();
    },

    toEditModal({ id }) {
      this.titleData = {
        type: this.$t('common.button.edit'),
        icon: 'md-create',
      };

      this.getById({
        issuerId: this.$route.params.idIssuer,
        id,
      }).then((data) => {
        this.modalData = data;
        this.showModal();
      });
    },

    removeItem() {
      const { id } = this.deleteItem;
      this.delete({
        issuerId: this.$route.params.idIssuer,
        id,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.cpDeleteAlert.$refs.deleteAlert.close();
        setTimeout(() => { this.deleteItem = null; }, 1000);
      });
    },

    saveChanges({ action, data }) {
      this[action]({
        issuerId: this.$route.params.idIssuer,
        data,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.hideModal();
      });
    },
  },
};
</script>
